<template>
    <div>
      <div class="potential">
        <rxNavBar title="房源特色"></rxNavBar>
      </div>
      <div class="featureDetail">
        <div v-if="roomHouse != undefined && roomHouse.length != 0">
          <div class="houseGenerationDetails" v-for="(item,index) in (roomHouse)">
            <div class="text-heating">{{item.roomName}}间特色：</div>
            <div class="houseGenerationDetailsItem" v-if="housingSourceList.length != 0">
                <div
                    class="divid-heating"
                    @click="choosePaperContent(item,items.id)"
                    v-for="items in housingSourceList"
                >
                  <!--                :class="items.isChoose ? 'text-contractActive' : 'text-contract'"   color="#ff5d3b"-->
                  <van-tag size="large" type="primary" class="tagItem"
                           :plain="(item.characteristic_arr.split(',')).indexOf(items.id+'') > -1?false:true"
                  >
                    {{ items.dictionaryTitle }}
                  </van-tag>
                </div>

            </div>
          </div>
        </div>
        <div
            class="part part-button part-button-enabled" @click="save">
            {{ saveFlag ? '保存' : '保存中' }}
        </div>
      </div>
      <loading v-if="loadingFlag"></loading>
<!--      <van-overlay :show="overlayshow" class="laodingStyle">-->
<!--        <van-loading />-->
<!--      </van-overlay>-->
    </div>
</template>

<script>
import {
  checkAndroid, checkIOS, getStaffId,
  globaluserId,
  money, openInWebview,
  responseUtil
} from "../../libs/rongxunUtil";
import { houseResourcesFeature,saveHouseResourcesFeature } from "../../getData/getData";
import {Button, Calendar, DatetimePicker, NavBar, NumberKeyboard, Popup, Toast,Tag, Loading, Overlay } from "vant";
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import loading from "../../components/rongxun/rx-loading/loading";
export default {
  name: "editRoomFeature",
  components: {
    // [NavBar.name]: NavBar,
    // [NumberKeyboard.name]: NumberKeyboard,
    // [Toast.name]: Toast,
    // [Button.name]: Button,
    // [Popup.name]: Popup,
    // [Calendar.name]: Calendar,
    // [DatetimePicker.name]: DatetimePicker,
    [Tag.name]: Tag,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    rxNavBar,
    loading,
  },
  data(){
    return{
      setHouse_id:'',
      roomHouse:[],
      housingSourceList:[],
      saveFlag:true,
      loadingFlag:false
    }
  },
  mounted() {
    // this.roomId = this.$route.query.room_id
    this.setHouse_id = this.$route.query.setHouse_id
    // this.contract_id = this.$route.query.contractId
    // this.subType = this.$route.query.roomType
    // this.changeStatus = this.$route.query.changeStatus != undefined ? this.$route.query.changeStatus : 0
    this.houseResourcesFeature()
    // this.initData()
  },
  methods:{
    //选择标签
    choosePaperContent(item,id) {
      !item.isChoose
          ? this.$set(item, 'isChoose', true)
          : this.$set(item, 'isChoose', false)
      for (var i = 0; i < this.housingSourceList.length; i++) {
        if (this.housingSourceList[i].id == id) {
          // 取消选中
          if (item.characteristic_arr.indexOf(id + ',') > -1) {
            item.characterLen--
            item.characteristic_arr = item.characteristic_arr.replace(id + ',', '')
          } else {
            // 选中特色
            if (item.characterLen < 3) {
              item.characterLen++
              item.characteristic_arr += id + ','
            } else {
              responseUtil.alertMsg(this, '最多只能选择三个房源特色')
            }
          }
        }
      }
      console.log(item.characteristic_arr)
    },
    //初始化
    houseResourcesFeature(){
      let that = this
      let initData = {}
      initData.setHouse_id = this.setHouse_id
      houseResourcesFeature(initData).then(function (response) {
        that.housingSourceList = response.data.data.housingSourceList  //房源特色
        that.roomHouse = response.data.data.roomHouseList  //套/间
        that.houseType = response.data.data.houseType  //房源类型
      })
    },
    //保存
    save(){
      let that = this
      let initData = {}
      if(!that.saveFlag){
        return
      }
      that.saveFlag = false
      that.loadingFlag = true
      initData.setHouse_id = that.setHouse_id
      initData.staff_id = getStaffId()
      initData.houseType = that.houseType
      initData.roomHouse = that.roomHouse // 合租 间房源数据
      saveHouseResourcesFeature(initData).then(function (response) {
        that.saveFlag = true
        that.loadingFlag = false
        responseUtil.dealResponse(that, response, () => {
          responseUtil.alertOK(that)
          that.$router.go(-1)
        })
      })
    },
  },
}
</script>

<style scoped lang="less">
.featureDetail{
  padding:0 0.4rem;
  .houseGenerationDetails{
    margin: 0.5rem 0rem;
    .text-heating {
      font-weight: 600;
      font-size: 0.4rem;
      height: auto;
    }
    .houseGenerationDetailsItem{
      //display: flex;
      display: flex;
      flex-wrap: wrap;
      .divid-heating{
        margin: 0 0.1rem;
        height: auto;
      }
    }
  }
  .part-button-enabled {
    height: 1.2rem;
    border-radius: 0.25rem;
    //background-color: #ededed;
    color: #ffffff;
    text-align: center;
    line-height: 1.2rem;
    font-size: 0.4rem;
    font-weight: 900;
    margin: 0.5rem 0rem;
    background-image: linear-gradient(to right, #ffc274, #ff5d3b);
  }
}





</style>